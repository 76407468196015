import React from 'react'
import Routing from './routing/Routing'
import './App.scss'

function App() {
  return (
    <div className="vm__app">
      <Routing/>
    </div>
  )
}

export default App
