import React, {lazy, Suspense} from 'react'
import {PATHS} from '../configs/nav-paths'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {FullScreenLoader} from '../components/ui/loaders/FullScreenLoader'
const Landing = lazy(() => import('../pages/landing/Landing'))
const TapLink = lazy(() => import('../pages/tap-link/TapLink'))
const NotFound = lazy(() => import('../pages/not-found/NotFound'))

const Routing = () => {
  return (
    <Suspense fallback={<FullScreenLoader/>}>
      <BrowserRouter>
        <Routes>
          <Route path={PATHS.tap_link} element={<TapLink/>}/>
          {/*<Route path={PATHS.landing} element={<Landing/>}/>*/}
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default Routing